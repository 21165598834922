import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"
import {
	adList
} from "@/api/website"
import {
	noticesList
} from "@/api/cms/notice"
import {
	floors,
	floatLayer,
	apiDefaultSearchWords
} from "@/api/pc"
import {
	mapGetters
} from "vuex"
import {
	couponList,
	receiveCoupon
} from "@/api/coupon"
import {
	goodsPage,
	timeList
} from "@/api/seckill"
import {
	goodsSkuPage
} from "@/api/goods/goods"
import {
	RecentTransactions,
	MarkingEnterprise,
	MarketData,
	InquiryDynamics,
	SupplyDemandPageList,
	ConsultationPageList,
	addSupplydemand,
	mobileCode,
	SupplyDemandInfo
} from "@/api/index.js"
import CountDown from "vue2-countdown"
export default {
	name: "index",
	components: {
		CountDown
	},
	data: () => {
		return {
			gqbtn:[
				{
					name:'求购'
				},
				{
					name:'供应'
				},
			],
			gqIndex:0,
			dialogImgVisible:false,
			dialogImageUrl:'',
			dialogVisible: false,
			SupplyDemandCount:0,
			purchase_contact_phone:'',
			purchase_time:'',
			purchase_unit:'',
			purchase_num:'',
			purchase_name:'',
			ConsultationPageList:[],
			SupplyDemandPageList:[],
			InquiryDynamics:[],
			MarketData:[],
			MarkingEnterprise:[],
			recentTransactions:[],//最新成交数据
			loadingAd: true,
			loadingFloor: true,
			adList: [],
			adLeftList: [],
			adRightList: [],
			noticeList: [],
			couponList: [],
			floorList: [],
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},
			indexFloatLayerNum: 0,
			shopApplyUrl: Config.baseUrl + "/shop/login/register",
			shopCenterUrl: Config.baseUrl + "/shop/index/index",
			uploadActionUrl: Config.baseUrl + '/api/upload/headimg',
			storeUrl: Config.baseUrl + "/store",
			isSub: false,
			imageList:[],
			contentText:'',
			siteId: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "距离结束",
			backgroundColor: "", // 顶部banner背景颜色
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "产品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false,
			goodsList:[],
			code:'',
			key:'',
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: "获取动态码",
				isSend: false
			}, // 动态码
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "产品" : "店铺"
		},
		"dynacodeData.seconds": {
			handler(newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 120,
						timer: null,
						codeText: "获取动态码",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.getAdList()
		this.getNotice()
		this.getBigAdList()
		this.getSmallAdList()
		if(this.city.id > 0){
			this.getIndexGoodsList();
		}else{
			this.getFloors();
			this.getFloatLayer();
			if(this.addonIsExit && this.addonIsExit.seckill == 1){
				this.getTimeList();
			}
			this.getCanReceiveCouponQuery();
		}
	
		// this.getFloatLayer()
		this.$store.dispatch("site/siteInfo") // 站点信息
		this.getDefaultSearchWords() // 默认搜索类型
		// if(this.addonIsExit && this.addonIsExit.seckill == 1){
		// 	this.getTimeList()
		// }
		// this.getCanReceiveCouponQuery()
		this.getRecentTransactions();
		this.getMarkingEnterprise();
		this.getMarketData();
		this.getInquiryDynamics();
		this.getSupplyDemandPageList()
		this.getConsultationPageList()
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount","city","defaultShopImage","token"]),
		optionLeft() {
			return {
				direction: 1,
				limitMoveNum: 1,
				openWatch: true,
				step:0.5
			}
		},
	},
	methods: {
		onExceed(){
			this.$message.error('最多只能上传9张')
		},
		handlePictureCardPreview(file) {
		  // 点开大图
		  this.dialogImageUrl = file.url;
		  this.dialogImgVisible = true;
		},
		handleRemove(file, fileList) {
			this.imageList.forEach((item,index)=>{
				if(item.name == file.name){
					this.imageList.splice(index,1)
				}
			})
		},
		handleSuccess(file, fileList) {
			let obj = {
				name:file.data.pic_name,
				url:file.data.pic_path
			}
			this.imageList.push(obj)
			console.log(file)
		},
		toAuthentication(){
			this.$router.push({ name: 'authentication'});
		},
		toApprove(){
			this.$router.push({ name: 'approve'});
		},
		handleClick(e){
			console.log(e)
			// const obj =JSON.parse(e.target.dataset.obj)
			const path = e.path || (e.composedPath && e.composedPath());
			let target = path.filter((r) => /items/.test(r.className));
			if (target.length) target = target[0];
			else return;
			const data = JSON.parse(target.getAttribute("data"));
			this.$router.pushToTab('/supplyDemand/detail-'+data.id)
		},
		toSupplyDemandDetail(id){
			this.$router.pushToTab('/supplyDemand/detail-'+id)
		},
		getCode(){
			if (this.dynacodeData.seconds != 120) return
			if(!this.purchase_contact_phone){
				this.$message.error('请输入手机号')
				return;
			}
			mobileCode({
				phone:this.purchase_contact_phone
			})
			.then(res=>{
				if(res.code>=0){
					this.key = res.data.key;
					if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
						this.dynacodeData.timer = setInterval(() => {
							this.dynacodeData.seconds--
							this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
						}, 1000)
					}
				}
			})
		},
		toSupplyDemandCenter(){
			this.$router.pushToTab('/supply_demand')
		},
		toConsultationList(){
			this.$router.pushToTab('/consultation')
		},
		toConsultationDetail(id){
			this.$router.pushToTab('/consultation/detail-'+id)
		},
		publishPurchase(){
			let arr = []
			this.imageList.forEach((item,index)=>{
				arr.push(item.url)
			})
			if (!this.token) {
				this.$message({
					message: '请先去登录',
					type: 'warning'
				});
				this.$router.push({ path: '/login'});
				return;
			}
			if(this.$util.memberStatus() != 2){
				this.$message({
					message: '您还未认证请先进行认证',
					type: 'warning'
				});
				this.$router.push({ name: 'authentication'});
				return;
			}
			var phonereg = /^1[3-9]\d{9}$/;
			if(!this.purchase_name){
				this.$message.error('物品名称不能为空')
				return;
			}
			if(!this.purchase_num){
				this.$message.error('采购量不能为空')
				return;
			}
			if(!this.purchase_unit){
				this.$message.error('采购单位不能为空')
				return;
			}
			if(!this.purchase_time){
				this.$message.error('采购的截止时间不能为空')
				return;
			}
			if(!this.purchase_contact_phone){
				this.$message.error('联系电话不能为空')
				return;
			}
			if(!phonereg.test(this.purchase_contact_phone)){
				this.$message.error('联系电话格式不正确')
				return;
			}
			if(!this.code){
				this.$message.error('请输入手机验证码')
				return;
			}
			addSupplydemand({
				goods_name:this.purchase_name,
				num:this.purchase_num,
				deadline_time:this.purchase_time,
				phone:this.purchase_contact_phone,
				unit:this.purchase_unit,
				code:this.code,
				key:this.key,
				describe:this.contentText,
				atlas:arr.toString(),
				type:this.gqIndex == 0 ? 1 : 2
			})
			.then(res=>{
				if(res.code>=0){
					this.$message(res.message)
					this.purchase_name = '';
					this.purchase_num = '';
					this.purchase_unit = '';
					this.purchase_time = '';
					this.purchase_contact_phone = '';
					this.code = '';
					this.key= ''
					
				}else{
					this.$message.error(res.message)
				}
			})
			.catch(err=>{
				this.$message.error(err.message)
			})
		},
		getConsultationPageList(){
			ConsultationPageList({
				page:1,
				page_size:4
			})
			.then(res=>{
				if(res.code>=0){
					this.ConsultationPageList = res.data.list;
				}
			})
			.catch(err=>{})
		},
		getSupplyDemandPageList(){
			SupplyDemandPageList({
				page:1,
				page_size:10,
				type:1,
			})
			.then(res=>{
				if(res.code>=0){
					this.SupplyDemandCount = res.data.count;
				}
			})
			SupplyDemandPageList({
				page:1,
				page_size:10
			})
			.then(res=>{
				if(res.code>=0){
					// this.SupplyDemandCount = res.data.count;
					this.SupplyDemandPageList = this.$util.deepClone( res.data.list );
					if( this.SupplyDemandPageList.length < 6  ){
						var new_arr = [];
						for(var i = 0 ; i < parseInt( ( 6 - this.SupplyDemandPageList.length ) / this.SupplyDemandPageList.length ) ; i++ ){
							new_arr.push(...this.SupplyDemandPageList)
						}
						if( ( 6 - this.SupplyDemandPageList.length ) % this.SupplyDemandPageList.length !=0 ){
							this.SupplyDemandPageList.forEach((item,index)=>{
								if( index < ( 6 - this.SupplyDemandPageList.length ) % this.SupplyDemandPageList.length ){
									new_arr.push(item)
								}
							})
						}
						this.SupplyDemandPageList.push(...new_arr)
					}
					if( this.SupplyDemandPageList.length % 3 != 0 ){
						var arr = [];
						for( var i = 0 ; i < 3 - this.SupplyDemandPageList.length % 3 ; i++ ){
							arr.push(this.SupplyDemandPageList[i])
						}
						this.SupplyDemandPageList.push(...arr)
					}
					this.SupplyDemandPageList.forEach(item=>{
						if(!item.logo){
							item.logo = this.defaultHeadImage;
						}
					})
				}
			})
			.catch(err=>{})
		},
		getCreateTime(timeStamp){
			if (timeStamp != undefined && timeStamp != "" && timeStamp > 0) {
			    var date = new Date()
			    date.setTime(timeStamp * 1000)
			    var m = date.getMonth() + 1
			    m = m < 10 ? "0" + m : m
			    var d = date.getDate()
			    d = d < 10 ? "0" + d : d
			    var h = date.getHours()
			    h = h < 10 ? "0" + h : h
			    var minute = date.getMinutes()
			    minute = minute < 10 ? "0" + minute : minute
			    return m + " / " + d + " " + h + " : " + minute
			} else {
			    return ""
			}
		},
		getInquiryDynamics(){
			InquiryDynamics()
			.then(res=>{
				if(res.code>=0){
					this.InquiryDynamics = this.$util.deepClone( res.data.list );
					//小于一页数据
					if( this.InquiryDynamics.length < 9  ){
						var new_arr = [];
						for(var i = 0 ; i < parseInt( ( 9 - this.InquiryDynamics.length ) / this.InquiryDynamics.length ) ; i++ ){
							new_arr.push(...this.InquiryDynamics)
						}
						if( ( 9 - this.InquiryDynamics.length ) % this.InquiryDynamics.length !=0 ){
							this.InquiryDynamics.forEach((item,index)=>{
								if( index < ( 9 - this.InquiryDynamics.length ) % this.InquiryDynamics.length ){
									new_arr.push(item)
								}
							})
						}
						this.InquiryDynamics.push(...new_arr)
					}
					//不是整页数据的倍数
					if( this.InquiryDynamics.length % 3 != 0 ){
						var arr = [];
						for( var i = 0 ; i < 3 - this.InquiryDynamics.length % 3 ; i++ ){
							arr.push(this.InquiryDynamics[i])
						}
						this.InquiryDynamics.push(...arr)
					}
				}
			})
			.catch(err=>{})
		},
		getMarketData(){
			MarketData()
			.then(res=>{
				if(res.code>=0){
					this.MarketData = res.data.list;
				}
			})
			.catch(err=>{})
		},
		getMarkingEnterprise(){
			MarkingEnterprise()
			.then(res=>{
				if(res.code>=0){
					this.MarkingEnterprise = res.data.list;
				}
			})
			.catch(err=>{})
		},
		getRecentTransactions(){
			RecentTransactions()
			.then(res=>{
				if(res.code>=0){
					this.recentTransactions = res.data.list;
				}
			})
			.catch(err=>{})
		},
		toLogin(){
			this.$router.pushToTab('/login')
		},
		logout() {
		    this.$store.dispatch("member/logout")
		},
		/*
		 * 解决秒杀组件复制出来的没有点击事件
		 */
		clickProps(e){
			if(!e.target.dataset.obj){ return }
			let item = JSON.parse(e.target.dataset.obj);
			this.$router.pushToTab('/promotion/seckill-' + item.id)
		},
		countDownS_cb() {},
		countDownE_cb() {
			this.seckillText = "活动已结束"
		},
		getIndexGoodsList() {
			goodsSkuPage({
				})
				.then(res => {
					this.goodsList = res.data.list;
				})
				.catch(err => {
				})
		},
		getAdList() {
			adList({
					keyword: "NS_PC_INDEX"
				})
				.then(res => {
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/* 秒杀图片处理 */
		goodsImg(imgStr) {
			let imgs = imgStr.split(',');
			return imgs[0] ? this.$util.img(imgs[0], { size: 'mid' }) : this.defaultGoodsImage;
		},
		/**
		 * 广告位大图
		 */
		getBigAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_LEFT"
				})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		/**
		 * 广告位小图
		 */
		getSmallAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_RIGHT"
				})
				.then(res => {
					this.adRightList = res.data.adv_list
					for (let i = 0; i < this.adRightList.length; i++) {
						if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		getNotice() {
			noticesList({
					page: 1,
					page_size: 5,
					receiving_type: "web"
				})
				.then(res => {
					this.noticeList = res.data.list
				})
				.catch(err => err)
		},
		//获取优惠券列表
		getCanReceiveCouponQuery() {
			couponList({
					activeName: "second",
					site_id: this.siteId
				})
				.then(res => {
					let data = res.data
					this.couponList = []
					if (data != null) {
						for (let i = 0; i < data.list.length; i++) {
							if (i < 5) {
								this.couponList.push(data.list[i])
							}
						}
						this.couponList.forEach(v => {
							v.useState = 0
						})
					}
				})
				.catch(err => {})
		},
		/**
		 * 领取优惠券
		 */
		receiveCoupon(item, index) {
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "second",
				platformcoupon_type_id: item.platformcoupon_type_id
			}

			receiveCoupon(data)
				.then(res => {
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "领取成功"
						this.$message({
							message: msg,
							type: "success"
						})
					} else {
						this.$message({
							message: msg,
							type: "warning"
						})
					}
					let list = this.couponList
					if (res.data.is_exist == 1) {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 1
							}
						}
					} else {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 2
							}
						}
					}

					this.isSub = false
					this.$forceUpdate()
				})
				.catch(err => {
					if (err.message == 'token不存在') {
						this.$router.pushToTab('/login')
					} else {
						this.$message.error(err.message);
					}
					this.isSub = false
				})
		},
		/**
		 * 点击优惠券
		 */
		couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * 去购买
		 */
		toGoodsList(item) {
			if (item.use_scenario != 1) {
				this.$router.push({
					path: "/list",
					query: {
						platformcouponTypeId: item.platformcoupon_type_id
					}
				})
			} else {
				this.$router.push("/list")
			}
		},
		/**
		 * 限时秒杀
		 */
		getTimeList() {
			timeList()
				.then(res => {
					if (res.code == 0 && res.data) {
						let time = new Date(res.timestamp * 1000)
						let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()
						res.data.list.forEach((v, k) => {
							if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
								let seckillId = v.id
								this.getGoodsList(seckillId)

								let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
								this.seckillTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: endTime
								}
							}
						})
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
		/**
		 * 秒杀商品
		 */
		getGoodsList(id) {
			goodsPage({
					page_size: 0,
					seckill_id: id,
					site_id: this.siteId
				})
				.then(res => {
					if (res.code == 0 && res.data.list) {
						this.listData = res.data.list
					}
				})
				.catch(err => {})
		},
		/**
		 * 图片加载失败
		 */
		defaultImg(mode,index,gIndex=''){
			switch (mode){
				case 'logo':
					this.MarkingEnterprise[index]['logo'] = this.defaultShopImage;
					break;
				case 'goods':
					this.MarkingEnterprise[index].goods_list[gIndex] = this.defaultGoodsImage;
					break;
				default:
					break;
			}
		},
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adLeftImageError(index) {
			this.adLeftList[index].adv_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adRightImageError(index) {
			this.adRightList[index].adv_image = this.defaultGoodsImage
		},
		getFloors() {
			floors()
				.then(res => {
					this.floorList = res.data;
					console.log(res,'res')
				})
				.catch(err => {
					console.log(err)
				})
		},
		getFloatLayer() {
			floatLayer()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.floatLayer = res.data
						this.floatLayer.link = JSON.parse(this.floatLayer.url)
						// 弹框形式，首次弹出 1，每次弹出 0

						if (!this.floatLayer.img_url) return

						if (parseInt(this.floatLayer.number) == 1) {
							var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;
							if (index_popwindow_count == '' || index_popwindow_count == 1) {
								this.floatLayer.is_show = true
								this.$store.commit("app/SET_FLOAT_LAYER", 1)
							}
						} else if (parseInt(this.floatLayer.number) == 0) {
							this.floatLayer.is_show = true
							this.$store.commit("app/SET_FLOAT_LAYER", 0)
						}
					}
				})
				.catch(err => err)
		},
		closeFloat() {
			this.floatLayer.is_show = false
			this.$forceUpdate()
			this.$store.commit("app/SET_FLOAT_LAYER", -1)
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * 默认搜索类型（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		handleCommand(command) {
			this.searchType = command
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		}
	}
}
