<template>
	<div>
		<!-- 首页固定区 -->
		<!-- :style="{ background: backgroundColor }" -->
		<div class="index-wrap" >
			<div class="index">
				<div class="banner">
					<el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="news">
					<div class="top">
						<img :src="$util.img('/upload/web/common_img.png')" alt="">
						<div class="welcome_text">欢迎来到新商汇-智汇商品交易平台</div>
					</div>
					<div class="btns" v-if="member">
						<div class="btn login" @click="toLogin()">切换账号</div>
						<div class="btn" @click="logout">退出</div>
					</div>
					<div class="btns" v-else>
						<div class="btn login" @click="$router.push('/login')">登录</div>
						<div class="btn" @click="$router.push('/register')">注册</div>
					</div>
					<div class="nav_btns">
						<div class="tit">商城服务</div>
						<div class="btns">
							<div class="item" @click="$router.pushToTab('/member/workbench')">
								<img :src="$util.img('/upload/web/personal_center.png')" alt="">个人中心
							</div>
							<div class="item" @click="$router.pushToTab('/member/bulk_order')">
								<img :src="$util.img('/upload/web/my_order.png')" alt="">我的订单
							</div>
							<div class="item" @click="$router.pushToTab('/member/footprint')">
								<img :src="$util.img('/upload/web/footer_print.png')" alt="">我的足迹
							</div>
							<div class="item" @click="$router.pushToTab('/member/collection')">
								<img :src="$util.img('/upload/web/my_collection.png')" alt="">我的关注
							</div>
							<div class="item" @click="$router.pushToTab(shopApplyUrl)">
								<img :src="$util.img('/upload/web/shop_settlement.png')" alt="">商家入驻
							</div>
							<div class="item" >
								<img :src="$util.img('/upload/web/my_order.png')" alt="" @click="toAuthentication()">企业认证
							</div>
							<!-- <div class="item" >
								<img :src="$util.img('/upload/web/my_order.png')" alt="" @click="toApprove()">企宗认证
							</div> -->
						</div>
					</div>
					<!-- <div class="notes">
						<div class="top">
							<div class="left">
								<div class="iconfont iconxiaoxi">
								</div>
								站内信
							</div>
							<div class="right" @click="$router.push('/cms/master')">查看<i class="el-icon-arrow-right"></i></div>
						</div>
					</div> -->
					<div class="notes">
						<div class="top">
							<div class="left">
								<img :src="$util.img('/upload/web/notes.png')" alt="">
								公告
							</div>
							<div class="right" @click="$router.push('/cms/notice')">更多<i class="el-icon-arrow-right"></i></div>
						</div>
						<div class="note_info" v-if="noticeList.length">{{noticeList[0].title}}</div>
						<div class="note_info empty" v-else>暂无公告</div>
					</div>
				</div>
			</div>
		</div>

		<div class="content">
			<!-- 领券中心 -->
			<div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0 && (city.id == 0 || !city)">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">领券中心</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>更多</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color">
								￥
								<span>{{ item.money }}</span>
							</p>
							<p class="coupon-term">满{{ item.at_least }}可用</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">立即领取</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>

			<!-- 广告 -->
			<div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div class="ad-wrap">
					<div class="ad-big">
						<div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
					<div class="ad-small">
						<div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)" @click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>
			</div>

			<!-- 限时秒杀 -->
			<div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && (city.id == 0 || !city)">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">限时秒杀</span>
							<span>{{ seckillText }}</span>
							<count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
							 :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
							 :dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
							<span>更多商品</span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content" @click="clickProps($event)">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
								<ul class="item" :style="{ width: 250 * listData.length + 'px' }" >
									<li v-for="(item, index) in listData" :key="index"  >
										<div class="seckill-goods" :data-obj="JSON.stringify(item)">
											<div class="seckill-goods-img"  :data-obj="JSON.stringify(item)">
												<img :src="goodsImg(item.goods_image)"  @error="imageError(index)"  :data-obj="JSON.stringify(item)"/>
											</div>
											<p  :data-obj="JSON.stringify(item)">{{ item.goods_name }}</p>
											<div class="seckill-price-wrap"  :data-obj="JSON.stringify(item)">
												<p class="ns-text-color"  :data-obj="JSON.stringify(item)">
													￥<span :data-obj="JSON.stringify(item)">{{ item.seckill_price }}</span>
												</p>
												<p class="primary-price"  :data-obj="JSON.stringify(item)">￥{{ item.price }}</p>
											</div>
										</div>
									</li>
								</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>

			<!-- 楼层区 -->
			<div class="content-div floor"  v-if="(city.id == 0 || !city)">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div>
				</div>
				<div class="recent_transaction">
					<div class="tit">最近成交</div>
					<div class="list">
						<div class="item" v-for="(item,index) in recentTransactions" :key="index" v-show="index<7" @click="toGoodsDetail(item.sku_id)">
							<div class="left">
								<div class="goods_name">{{item.sku_name}}</div>
								<div class="time">{{$util.timeStampTurnTime(item.create_time)}}</div>
							</div>
							<div class="right">￥{{item.goods_money}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="companys">
				<div class="tit">
					<div class="left">星标企业</div>
					<div class="right" @click="$router.pushToTab('/street')">更多<i class="el-icon-arrow-right"></i></div>
				</div>
				<div class="company_list">
					<div class="item" v-for="(item,index) in MarkingEnterprise" v-if="index<4" :key="index">
						<div class="item_top">
							<div class="left">
								<div class="logo">
									<img :src="$img(item.logo)" @error="defaultImg('logo',index)" alt="" />
								</div>
								<div class="company_info" @click="$router.pushToTab('/shop-'+item.site_id)">
									<div class="name">{{item.site_name}}</div>
									<div class="area">主营商品：{{item.seo_keywords}}</div>
								</div>
							</div>
							<div class="right" @click="$router.pushToTab('/shop-'+item.site_id)"><img :src="$util.img('/upload/web/more.png')" alt=""></div>
						</div>
						<div class="pro_imgs">
							<img @click="$router.pushToTab('/sku-'+gItem.sku_id)" v-for="(gItem,gIndex) in item.goods_list" :key="gIndex" :src="$util.img(gItem.goods_image)" v-show="gIndex<5" @error="defaultImg('goods',index,gIndex)" alt="">
						</div>
					</div>
				</div>
			</div>
			
			<div class="company_compare">
				<div class="offer">
					<div class="top">
						<div class="tit">询价动态</div>
						<!-- <div class="right">更多<i class="el-icon-arrow-right"></i></div> -->
					</div>
					<div class="dynamic_state">
						<vue-seamless-scroll :data="InquiryDynamics" :class-option="optionLeft" class="seamless-warp">
							<ul class="item" >
								<li class="items" v-for="(item,index) in InquiryDynamics" :key="index">
									<div class="info">
										<div class="name">{{item.sku_name}}</div>
										<div class="status">{{item.state_name}}</div>
									</div>
									<div class="date">交期 {{ $util.timeStampTurnTime(item.delivery_time).split(' ')[0] }}</div>
									<div class="company_info">
										<div class="company">{{item.corporate_name}}</div>
										<div class="time">{{getCreateTime(item.create_time)}}</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
				<div class="price_data">
					<div class="tit">行情数据</div>
					<div class="text">
						<div class="left">商品名称</div>
						<div class="right">最新成交价</div>
					</div>
					<div class="datas">
						<div class="item" v-for="(item,index) in MarketData" v-show="index<8" :key="index">
							<div class="name">{{item.sku_name}}</div>
							<div class="price">￥{{item.goods_money}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="supply_center">
				<div class="supply_info">
					<div class="tit">
						<div class="left" >
							<div class="name">供求中心</div>
						</div>
						<div class="right" @click="toSupplyDemandCenter()">更多<i class="el-icon-arrow-right"></i></div>
					</div>
					<div class="content" @click="handleClick($event)">
						<vue-seamless-scroll :data="SupplyDemandPageList" :class-option="optionLeft" class="seamless-warp1" >
							<ul class="item">
								<li class="items" :data="JSON.stringify(item)" v-for="(item,index) in SupplyDemandPageList" :key="index" >
									<!-- @click="toSupplyDemandDetail(item.id)" -->
									<div class="top">
										<div class="name">
											<img v-if="item.type ==2" :src="$img('/upload/web/supply.png')" alt="">
											<img v-else :src="$img('/upload/web/request.png')" alt="">{{item.goods_name}}
										</div>
									</div>
									<div class="middle">
										<div class="num">采购<span>1</span>批</div>
										<div class="time">时间：{{ $util.timeStampTurnTime(item.deadline_time).split(' ')[0]}}</div>
									</div>
									<div class="company">
										<img :src="$img(item.logo)" alt="">{{item.corporate_name}}
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
				<div class="publish_info">
					<div class="tit">发布供求信息</div>
					<div class="num">已有<span>{{SupplyDemandCount}}</span>发布供求信息</div>
					<div class="form_info">
						<div class="choose-btn">
							<div class="choose-type">
								类型:
							</div>
							<div class="btn" v-for="(item,index) in gqbtn" :key="index" @click="gqIndex = index" :class="index == gqIndex ? 'gong-styles' : ''">
								{{item.name}}
							</div>
						</div>
						<input type="text" placeholder="物品名称" class="goods_name" v-model="purchase_name">
						<div class="spec">
							<input type="text" placeholder="采购量" class="purchase_num" v-model="purchase_num">
							<input type="text" placeholder="单位" class="unit" v-model="purchase_unit">
						</div>
						<el-date-picker class="choose_time" value-format="yyyy-MM-dd" v-model="purchase_time" type="date" placeholder="截止时间 例如:2023-08-10"></el-date-picker>
						<div class="more">
							<span class="more-add" @click="dialogVisible = true">+ 图文发布</span>
						</div>
						<input type="text" placeholder="联系电话" class="mobile" v-model="purchase_contact_phone">
						<div class="verification_code">
							<input type="text" placeholder="手机验证码" class="code" v-model="code">
							<div class="btn" @click="getCode()">{{ dynacodeData.codeText }}</div>
						</div>
					</div>
					<div class="publish">
						<div class="btn" @click="publishPurchase()">立即发布</div>
					</div>
				</div>
			</div>
			
			<div class="data_info">
				<div class="top">
					<div class="tit">数据资讯</div>
					<div class="right" @click="toConsultationList()">更多<i class="el-icon-arrow-right"></i></div>
				</div>
				<div class="infos">
					<div class="item" v-for="(item,index) in ConsultationPageList" :key="index" @click="toConsultationDetail(item.id)">
						<div class="left">
							<img :src="$util.img(item.image_url)" alt="">
						</div>
						<div class="right">
							<div class="tops">
								<div class="name">{{item.title}}</div>
								<div class="content">{{item.introduction}}</div>
							</div>
							<div class="bottom">
								<div class="time"><i class="el-icon-time"></i>{{ $util.timeStampTurnTime(item.create_time).split(' ')[0] }}</div>
								<div class="browse"><i class="el-icon-view"></i>{{item.read}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<!-- 分站商品列表 -->
			<div v-if="false">
				<div class="more">
					<router-link to="/list">
						<span>更多</span>
						<i class="iconfont iconarrow-right"></i>
					</router-link>
				</div>
			    <div class="goods-info" v-if="goodsList.length">
					<div
						class="item"
						v-for="(item, index) in goodsList"
						:key="item.goods_id"
						@click="$router.pushToTab({ path: '/sku-' + item.sku_id })"
					>
						<img
						class="img-wrap"
						:src="$img(item.sku_image, { size: 'mid' })"
						@error="imageError(index)"
						/>
						<div class="price-wrap">
						<div class="price">
							<p>￥</p>
							{{ item.discount_price }}
						</div>
						<div class="market-price">￥{{ item.market_price }}</div>
						</div>
						<div class="goods-name">{{ item.goods_name }}</div>
						<div class="sale-num">
						<p>{{ item.sale_num || 0 }}</p>人付款
						</div>
						<div class="saling">
						<div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
						<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show && city.id == 0" >
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>

			<!-- 悬浮搜索 -->
			<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
				<div class="header-search">
					<el-row>
						<el-col :span="6">
							<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
						</el-col>
						<el-col :span="13">
							<div class="in-sousuo">
								<div class="sousuo-box">
									<el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="goods">产品</el-dropdown-item>
											<el-dropdown-item command="shop">店铺</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
									<el-button type="primary" size="small" @click="search">搜索</el-button>
								</div>
							</div>
							<div class="search_texts">
								<span>热门钢材</span>
								<span>热门煤炭</span>
								<span>家装建材</span>
								<span>钢材钢管</span>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="cart-wrap">
								<el-image  v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError" fit="contain" class="img">
								</el-image>
								<el-image class="img" v-else :src="require('@/assets/images/wxewm.png')"></el-image>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" title="图文上传">
			<div class="more-item">
				<div class="dialog-title">
					添加图片：
				</div>
				<el-upload ref="upload" :action="uploadActionUrl" class="upload-box"
				:limit="9"
				 list-type="picture-card" :on-success="
					(file, fileList) => {
						return handleSuccess(file, fileList);
					}
				" :on-preview="handlePictureCardPreview" :on-remove="
					(file, fileList) => {
						return handleRemove(file, fileList);
					}
				"
				:on-exceed="onExceed"
				>
				<i class="el-icon-plus"></i>
				</el-upload>
			</div>
			<div class="more-item">
				<div class="dialog-title">
					简介：
				</div>
				<div class="content">
					<el-input
						type="textarea"
						placeholder="请输入简介信息"
						v-model="contentText"
						class="buyer-message"
						maxlength="140"
						show-word-limit
						resize="none"
					></el-input>
				</div>
			</div>
			<div class="publish-close">
				<div class="btn" @click="dialogVisible = false">确定</div>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="dialogImgVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
	</div>
</template>

<script>
	import floorStyle1 from './components/floor-style-1.vue';
	import floorStyle2 from './components/floor-style-2.vue';
	import floorStyle3 from './components/floor-style-3.vue';
	import index from './_index/index.js';
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		name: 'index',
		components: {
			floorStyle1,
			floorStyle2,
			floorStyle3,
			vueSeamlessScroll
		},
		mixins: [index]
	};
</script>
<style lang="scss">
	.choose_time{
		width: auto !important;
		margin-bottom: 10px;
		::-webkit-input-placeholder{
			color: #888;
		}
		.el-input__prefix{
			display: none;
		}
		.el-input__inner{
			border-radius: 0;
			border:1px solid #E5E5E5;
			height: auto !important;
			padding: 8px;
			color: #333;
			font-size: 12px;
			line-height: 16px;
		}
		.el-input__icon{
			line-height: 34px;
		}
	} 
	.index .banner .el-carousel__indicators.el-carousel__indicators--horizontal{
		bottom: 24px !important;
		display: flex;
		align-items: center;
	}
	.index .banner .el-carousel__indicator--horizontal{
		padding: 0;
		width: 38px;
		height: 8px;
		border-radius: 10px;
		margin-right: 12px;
		.el-carousel__button{
			width: 100%;
			height: 100%;
			border-radius: 10px;
			background-color: rgba(255, 255, 255, 0.8);
		}
		&:last-child{
			margin-right: 0;
		}
		&.is-active{
			.el-carousel__button{
				background-color: #01A98F;
				opacity: 0.8;
			}
		}
		
		
	}
</style>
<style lang="scss" scoped>
	@import './_index/index.scss';
</style>

<style lang="scss" scoped>
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}
	.more{
		width: 1200px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
		a{
			color:#ff547b
		}
	}
	.goods-info {
		width:1200px;
		margin:5px auto 40px;
		display: flex;
		flex-wrap: wrap;
		.item {
			width: 202px;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee;
			padding: 10px;
			position: relative;
			&:nth-child(5 n) {
			  margin-right: initial !important;
			}
			&:hover {
			  border: 1px solid $base-color;
			}
			.img-wrap {
			  width: 198px;
			  height: 198px;
			  cursor: pointer;
			}
			.goods-name {
			  overflow: hidden;
			  text-overflow: ellipsis;
			  white-space: nowrap;
			  cursor: pointer;
			  &:hover {
				color: $base-color;
			  }
			}
			.price-wrap {
			  display: flex;
			  align-items: center;
			  .price {
				display: flex;
				color: $base-color;
				font-size: $ns-font-size-lg;
				p {
				  font-size: $ns-font-size-base;
				  display: flex;
				  align-items: flex-end;
				}
			  }
			  .market-price {
				color: #838383;
				text-decoration: line-through;
				margin-left: 10px;
			  }
			}
			.sale-num {
			  display: flex;
			  color: #838383;
			  p {
				color: #4759a8;
			  }
			}
			.saling {
			  display: flex;
			  font-size: $ns-font-size-sm;
			  line-height: 1;
			  .free-shipping {
				background: $base-color;
				color: #ffffff;
				padding: 3px 4px;
				border-radius: 2px;
				margin-right: 5px;
			  }
			  .promotion-type {
				color: $base-color;
				border: 1px solid $base-color;
				display: flex;
				align-items: center;
				padding: 1px;
			  }
			}
		}
	}
	.more{
		display: flex;
		justify-content: center;
		margin-bottom: 8px;
		cursor: pointer;
		border: 1px solid #0DAD94;
		border-radius: 2px;
		height: 30px;
		line-height: 30px;
		width: 100%;
	}
	.more-add{
		color: #0DAD94;
		font-size: 12px;
		text-align: right;
	}
	.more-item{
		display: flex;
		margin-bottom: 25px;
		.dialog-title{
			width: 80px;
			margin-right: 15px;
		}
	}
	.buyer-message{
		width: 300px;
	}
	.publish-close{
		display: flex;
		justify-content: center;
		.btn{
			cursor: pointer;
			border-radius: 50px;
			color: #fff;
			font-size: 14px;
			line-height: 30px;
			padding: 6px 42px;
			background-color: #0DAD94;
			width: 100px;
			text-align: center;
		}
	}
	.upload-box{
		width: calc(100% - 100px);
	}
	.upload-box{
		width: calc(100% - 100px);
	}
</style>
