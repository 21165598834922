<template>
	<div class="floor-style-3">
		<div class="item-wrap">
			<div class="head-wrap">
				<div class="left">
					<div class="tit" v-if="data.value.title.value.text" @click="$router.pushToTab(data.value.title.value.link.url)">
						{{ data.value.title.value.text }}
					</div>
					<div class="category_list">
						<li v-for="(item, index) in data.value.categoryList.value.list" :key="index">
							<router-link target="_blank" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{ item.category_name }}</router-link>
						</li>
					</div>
				</div>
				<div class="right" @click="$router.pushToTab('/list')">更多<i class="el-icon-arrow-right"></i></div>
			</div>
			<div class="body-wrap">
				<div class="left-img-wrap">
					<img v-if="data.value.leftImg.value.url" :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
				</div>
				<ul class="right-goods-wrap">
					<li v-for="(item, index) in data.value.rightGoodsList.value.list" :key="index" @click="goSku(item.sku_id)" v-if="index < 8">
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageErrorRight(index)" /></div>
						<div class="goods_name">{{ item.goods_name }}</div>
						<div class="price">￥{{ item.discount_price }}</div>
					</li>
				</ul>
				<ul class="bottom-goods-wrap">
					<li v-for="(item, index) in data.value.bottomGoodsList.value.list" :key="index" @click="goSku(item.sku_id)">
						<div class="info-wrap">
							<h4>{{ item.goods_name }}</h4>
							<p class="ns-text-color">{{ item.introduction }}</p>
						</div>
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageErrorBottom(index)" /></div>
					</li>
				</ul>

				<ul class="brand-wrap">
					<li v-for="(item, index) in data.value.brandList.value.list" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
						<img alt="品牌图片" :src="$img(item.image_url)" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-3',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageErrorRight(index) {
			this.data.value.rightGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		},
		imageErrorBottom(index) {
			this.data.value.bottomGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
.floor-style-3 {
	overflow: hidden;
	background-color: #fff;
	.head-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 13px 20px;
		border-bottom: 1px solid #f7f8fa;
		.left{
			flex:1;
			display: flex;
			align-items: center;
			.tit{
				color: #333;
				font-size: 18px;
				line-height: 26px;
				font-weight: 600;
				margin-right: 60px;
			}
			.category_list{
				display: flex;
				align-items: center;
				li{
					padding: 8px 21px;
					color: #333333;
					font-size: 12px;
					cursor: pointer;
					line-height: 16px;
					border: 1px solid transparent;
					margin-right: 20px;
					a{
						color: #333333;
						font-size: 12px;
						line-height: 16px;
					}
					&:hover{
						border-color: #00A98F;
						color: #00A98F;
						font-weight: 600;
						a{
							color: #00A98F;
						}
					}
				}
			}
				}
				.right{
					color: #999;
					font-size: 12px;
					line-height: 16px;
					cursor: pointer;
					.el-icon-arrow-right{
						color: #999;
						margin-left: 6px;
					}
				}
	}
	.body-wrap {
		.left-img-wrap {
			width: 226px;
			height: 468px;
			float: left;
			margin-top: 10px;
			cursor: pointer;
			img {
				max-width: 100%;
				max-height: 100%;
				display: block;
			}
		}
		.right-goods-wrap {
			margin-left: 244px;
			padding-right: 10px;
			display: flex;
			flex-wrap: wrap;
			li {
				width: calc( ( 100% - 24px ) / 4 );
				background: #fff;
				margin-right: 8px;
				cursor: pointer;
				padding: 16px 16px 13px;
				box-sizing: border-box;
				.img-wrap {
					width: 100%;
					height: 134px;
					margin: 0 auto 12px;
					line-height: 134px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				.goods_name{
					color: #333;
					font-size: 12px;
					line-height: 17px;
					margin-bottom: 6px;
					text-align: center;
					height: 34px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.price {
					color: #E80110;
					font-size: 12px;
					line-height: 24px;
					text-align: center;
				}
				&:nth-child(4n){
					margin-right: 0;
				}
				
			}

			
		}
	}
	.bottom-goods-wrap {
		overflow: hidden;
		display: flex;
		li {
			flex: 1;
			background: #fff;
			border-width: 0 0 1px 1px;
			border-color: #f9f9f9;
			border-style: solid;
			cursor: pointer;
			&:first-child {
				border-left: 0;
			}

			.info-wrap {
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				h4 {
					font-size: 14px;
					margin: 0 10px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 90px;
					font-weight: normal;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 0 20px;
					width: 70px;
				}
			}
			.img-wrap {
				width: 70px;
				height: 70px;
				line-height: 70px;
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				padding: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.brand-wrap {
		display: flex;
		background: #fff;
		li {
			flex: 1;
			height: 50px;
			cursor: pointer;
			line-height: 50px;
			text-align: center;
			background: #fff;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
</style>
